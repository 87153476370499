'use client'

import React, { useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { useTheme } from '@/contexts/themeProvider';
import classNames from 'classnames';
import { spreadArrToString, upperCaseFirst } from '@/utils/helpers';

interface DataItem {
  [key: string]: any;
}

interface DataItems {
  [key: string]: DataItem[];
}

type Props = {
  data: DataItem;
  isOpen: boolean;
  exclude?: string[]
};

const DataGrid: React.FC<Props> = ({ data, isOpen, exclude=[""] }) => {
  const [nestedData, setNestedData] = React.useState<DataItem>({});
  const [nestedDatas, setNestedDatas] = React.useState<DataItems>({});

  useEffect(() => {
    const data_ = JSON.parse(JSON.stringify(data));
    const newNestedData: DataItem = {};
    const newNestedDatas: DataItems = {};
    Object.entries(data_).forEach(([key, value]) => {
      if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
        // console.log("scrapped obj", key)
        newNestedData[key] = value;
        delete data_[key];
      }
      if (typeof value === 'object' && Array.isArray(value) && value !== null) {
        // console.log("scrapped arr", key)
        newNestedDatas[key] = value;
        delete data_[key];
      }
    });
    setNestedData(newNestedData);
    setNestedDatas(newNestedDatas);
    // console.log("newNestedDatas", newNestedDatas)
  }, [data, isOpen]);

  // useEffect(() => {
  //   console.log("nestedData", nestedDatas);
  // }, [nestedDatas])

  // spreadArrToString
  const renderGridItems = (obj: DataItem) =>
    Object.entries(obj).map(([key, value]) => (
      (typeof value !== 'object') && (exclude.length > 0 && !exclude.includes(key)) && <div key={key} className="grid-item">
        <div className="text-sm font-bold text-slate-500 dark:text-neutral-400">{upperCaseFirst(key.replace(/_/g, ' '))}</div>
        <div className="text-sm">{value.length > 0 ? value : "_"}</div>
      </div>
    ));

    const filterDatas = ["available_account_type"]

  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-10">
        {renderGridItems(data)}
      </div>

      <div>
        {Object.keys(nestedData).length > 0 && Object.keys(nestedData).map((_, i) => (
          <div className="mb-10" key={i}>
            <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-3 text-sm font-bold">
              {Object.keys(nestedData)[i]}
            </div>
            <DataGrid data={nestedData[Object.keys(nestedData)[i]]} isOpen={isOpen} exclude={exclude} />
          </div>
        ))}
      </div>

      <div>
        {Object.keys(nestedDatas).length > 0 && Object.keys(nestedDatas).map((h, j) => (
          !filterDatas.includes(h) &&
          <div key={j}>
            <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-3 text-sm font-bold">
              {upperCaseFirst(h.replace(/_/g, ' '))}
            </div>
            {
              Object.values(nestedDatas[h]).length > 0 &&
              <div className='w-full mb-10'>
                <div className="relative overflow-auto max-h-[200px]">
                  <table className="w-full text-xs text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-neutral-700 dark:text-gray-400">
                      <tr>
                        {
                          Object.keys(Object.values(nestedDatas[h])[0]).filter(f => !exclude.includes(f)).map((h, i) => (
                            <th scope="col" className="px-6 py-3" key={i}>
                              {h.replace(/_/g, ' ')}
                            </th>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        Object.values(nestedDatas[h]).map((d, k) => (
                          <tr key={k} className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700">
                            {
                              Object.keys(Object.values(nestedDatas[h])[0]).filter(f => !exclude.includes(f)).map((e, i) => (
                                <td className="px-6 py-4">
                                  {Array.isArray(d[e]) ? spreadArrToString(d[e]) : d[e]}
                                </td>
                              ))
                            }
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            }
          </div>
        ))}
      </div>
    </>
  );
};

function AutoGenTableViewModal({
  children,
  data,
  title,
  exclude = [""]
}: Readonly<{
  title: string,
  children: React.ReactNode,
  data: any,
  exclude?: string[]
}>) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { resolvedTheme } = useTheme();

  return (
    <>
      <div onClick={onOpen} className='inline-block cursor-pointer'>{children}</div>

      <Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} size={"4xl"} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>{title}</ModalHeader>
          <ModalCloseButton className={classNames('outline-none', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })} />
          <ModalBody className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>
            <DataGrid data={data} isOpen={isOpen} exclude={exclude} />
          </ModalBody>
          <ModalFooter className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>
            <button onClick={onClose}>Close</button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AutoGenTableViewModal