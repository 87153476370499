'use client'

import React, { useEffect, useMemo, useState } from 'react'
import UpdateBusinessServiceModal from '@/components/modals/UpdateBusinessServiceModal';
import http from '@/lib/http';
import { Skeleton } from '@chakra-ui/react';
import { FaAngleRight } from 'react-icons/fa6'
import { useLocation } from 'react-router-dom';
import FilterForm from '@/components/FilterForm';
import AutoGenNormalViewModal from '@/components/modals/AutoGenNormalViewModal';

function BusinessServicesDetailsClient() {

    const [details, setDetails] = useState<any[]>([]);
    const [isFetching, setIsFetching] = useState(true);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("");
    const {pathname} = useLocation();
    
    const [paginationInput, setPaginationInput] = useState<any>();
    const filterInputs = {
        // currency: "ngn",
        slug: "",
        collection: "",
        active: "",
        order_by: ""
    }
    function setPaginate(resp: any) {
        setPaginationInput({
            counts: resp.counts,
            totalPages: resp.totalPages,
            currentPage: resp.currentPage,
            listPerPage: resp.listPerPage
        });
    }

    async function fetchServices() {
        try {
            const resp = await http("business/services/details", { business_id: getBusinessId(), limit: 10 });
            console.log("resp", resp)
            setDetails(resp.data)
            setPaginate(resp);
            setIsFetching(false);
        } catch (error) {
            console.log(error)
            setIsFetching(false);
        }
    }

    function updateService(service_id: string, newActive: string) {
        const index = details.findIndex(service => service.service_id === service_id);
        if (index !== -1) {
            const updatedServices = [...details];
            updatedServices[index].active = newActive;
            setDetails(updatedServices);
        }
    };

    useEffect(() => {
        fetchServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filteredDetails = useMemo(() => {
        if (details.length === 0) return [];
        //  if(search === "");
        const sorted_ = sort === "date-asc" ?
            details.slice().sort((a: any, b: any) => new Date(a.created_on).getTime() - new Date(b.created_on).getTime()) :
            sort === "date-desc" ?
                details.slice().sort((a: any, b: any) => new Date(b.created_on).getTime() - new Date(a.created_on).getTime()) :
                sort === "z-a" ?
                    details.slice().sort((a: any, b: any) => b.slug.toLowerCase() < a.slug.toLowerCase() ? -1 : 1) :
                    details.slice().sort((a: any, b: any) => a.slug.toLowerCase() < b.slug.toLowerCase() ? -1 : 1);

        const filtered_ = search.length > 0 ?
            sorted_.filter((e: any) => e.slug.toLowerCase().includes(search.toLowerCase())) :
            sorted_;

        return filtered_;

    }, [search, sort, details]);

    function getBusinessId() {
        const split_path = pathname.split('/');
        return split_path[3];
    }

    return (
        <>
            <div className='overflow-auto max-w-full md:max-w-full mb-10'>
                <div className='text-xs flex text-slate-500 items-center gap-3'>
                    <div>Businesses</div>
                    <div><FaAngleRight /></div>
                    <div>Services</div>
                    <div><FaAngleRight /></div>
                    <div>Details</div>
                </div>
            </div>

            <div className='mb-10 text-xl'>Services Details</div>

            <div className='mb-8 flex gap-5 items-center'>
                <input value={search} type="text" className='px-3 py-3 sm:w-[400px] w-[190px] text-sm border-2 border-neutral-600 dark:bg-neutral-700' onChange={(e) => setSearch(e.target.value)} placeholder='Enter Search Keyword' />
                <div className='flex-1'>
                    <div className='text-xs'>Sort by</div>
                    <select className='text-xs p-1 dark:bg-neutral-700 w-[110px]' value={sort} onChange={(e) => setSort(e.target.value)}>
                        <option value="a-z">A-Z</option>
                        <option value="z-a">Z-A</option>
                        <option value="date-asc">Date (Ascending)</option>
                        <option value="date-desc">Date (Descending)</option>
                    </select>
                </div>
            </div>

            <hr className='mb-6 border-slate-300 dark:border-neutral-700' />

            <FilterForm
                url="business/services/details"
                payload={{business_id: getBusinessId()}}
                inputs={filterInputs}
                paginationInput={paginationInput}
                isLoading={isFetching}
                setIsLoading={setIsFetching}
                setState={setDetails}
                setPaginationInput={setPaginationInput}
            />

            <div className='mb-10 w-full'>

                <div className="relative overflow-x-auto">
                    <table className="w-full text-xs text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-neutral-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Service slug
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Currency type
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Provider Amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Partner Amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Active
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                !isFetching ? filteredDetails.map((d, k) => (
                                    <tr key={k} className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            {d.slug}
                                        </th>
                                        <td className="px-6 py-4">
                                            {d.currency_type}
                                        </td>
                                        <td className="px-6 py-4">
                                            {d.amount}
                                        </td>
                                        <td className="px-6 py-4">
                                            {d.provider_amount}
                                        </td>
                                        <td className="px-6 py-4">
                                            {d.partner_amount}
                                        </td>
                                        <td className="px-6 py-4">
                                            {d.active}
                                        </td>
                                        <td className="px-6 py-4 flex gap-3">
                                            <AutoGenNormalViewModal title='Service' data={d}><span className='font-bold text-blue-600'>View</span></AutoGenNormalViewModal>
                                            <UpdateBusinessServiceModal data={d} updateService={updateService}><span className='font-bold text-blue-600'>Update</span></UpdateBusinessServiceModal>
                                        </td>
                                    </tr>
                                )) :
                                    Array.from(Array(5)).map((k, i) => (
                                        <tr key={i} className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700">
                                            <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                <Skeleton h={'20px'} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <Skeleton h={'20px'} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <Skeleton h={'20px'} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <Skeleton h={'20px'} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <Skeleton h={'20px'} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <Skeleton h={'20px'} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <Skeleton h={'20px'} />
                                            </td>
                                        </tr>
                                    ))
                            }
                        </tbody>
                    </table>
                </div>

            </div>
        </>
    )
}

export default BusinessServicesDetailsClient