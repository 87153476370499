'use client'

import http from '@/lib/http';
import { BusinessMetric } from '@/types/global.types';
import { Skeleton } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { FaLongArrowAltRight } from 'react-icons/fa';
import { FaAngleRight } from "react-icons/fa6";
import { FcCancel, FcCheckmark, FcSynchronize, FcViewDetails } from 'react-icons/fc';
import { PiDotsThreeOutlineBold, PiUsersThreeBold } from 'react-icons/pi';
import { Link, useLocation } from 'react-router-dom';

function BusinessOverviewClient() {

    const [isFetching, setIsFetching] = useState(true);
    const [metric, setMetric] = useState<BusinessMetric | null>(null);
    const {pathname} = useLocation();

    async function getBusinessMetric() {
        try {
            const resp = await http<BusinessMetric>("business/metrics/details", {business_id: getBusinessId()});
            console.log("metric", resp.data);
            setMetric(resp.data);
            setIsFetching(false);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getBusinessMetric();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getBusinessId() {
        const split_path = pathname.split('/');
        return split_path[3];
    }

    return (
        <>
            <div className='text-xs text-slate-500 flex items-center gap-3 mb-10'>
                <div>Businesses</div>
                <div><FaAngleRight /></div>
                <div>overview</div>
            </div>

            <div className='mb-10 text-xl'>Business Overview</div>

            <div className='mb-10'>
                <div className='flex justify-between items-center'>
                    <div className='font-bold text-sm mb-4 px-1 flex items-center gap-3'>
                        <PiUsersThreeBold className='w-6 h-6' />
                        <div>Users</div>
                    </div>
                    <Link to={`/d/businesses/${getBusinessId()}/users`} className='flex items-center gap-2'>
                        <div className='text-xs underline font-bold'>View</div>
                        <div className='p-1 bg-neutral-200 dark:bg-neutral-800 rounded-full'><PiDotsThreeOutlineBold className='w-4 h-4' /></div>
                    </Link>
                </div>
                <div className='grid grid-cols-2 sm:grid-cols-3 sm:gap-6 gap-3'>

                    <div className='sm:px-5 px-3 py-2 sm:py-5 bg-slate-50 dark:bg-neutral-800 rounded-lg shadow-md'>
                        <div className='text-[12px] mb-4 font-bold inline-block px-0'>Total</div>
                        <div className='flex gap-3 items-center'>
                            <div className='bg-sky-100 sm:p-3 p-1'>
                                <FcViewDetails className='w-7 h-7' />
                            </div>
                            <div className='w-full'>
                                <div className='mb-3 text-[10px]'>
                                    Total users registered or created
                                </div>
                                <div className='flex justify-between items-center'>
                                    <div className='text-xl text-opacity-80 '>{!isFetching && metric ? <span>{metric.users.total}</span> : <Skeleton h={'20px'} w={'50px'} />}</div>
                                    <div className='flex items-center gap-1 text-[10px]'>
                                        <div>view</div>
                                        <FaLongArrowAltRight className='w-2 h-2' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='sm:px-5 px-3 py-2 sm:py-5 bg-slate-50 dark:bg-neutral-800 rounded-lg shadow-md'>
                        <div className='text-[12px] mb-4 font-bold inline-block px-0'>Pending</div>
                        <div className='flex gap-3 items-center'>
                            <div className='bg-orange-100 sm:p-3 p-1'>
                                <FcSynchronize className='w-7 h-7' />
                            </div>
                            <div className='w-full'>
                                <div className='mb-3 text-[10px]'>
                                    users awaiting approval
                                </div>
                                <div className='flex justify-between items-center'>
                                    <div className='text-xl text-opacity-80 '>{!isFetching && metric ? <span>{metric.users.pending}</span> : <Skeleton h={'20px'} w={'50px'} />}</div>
                                    <div className='flex items-center gap-1 text-[10px]'>
                                        <div>view</div>
                                        <FaLongArrowAltRight className='w-2 h-2' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='sm:px-5 px-3 py-2 sm:py-5 bg-slate-50 dark:bg-neutral-800 rounded-lg shadow-md'>
                        <div className='text-[12px] mb-4 font-bold inline-block px-0'>Approved</div>
                        <div className='flex gap-3 items-center'>
                            <div className='bg-green-100 sm:p-3 p-1'>
                                <FcCheckmark className='w-7 h-7' />
                            </div>
                            <div className='w-full'>
                                <div className='mb-3 text-[10px]'>
                                    Total Approved users
                                </div>
                                <div className='flex justify-between items-center'>
                                    <div className='text-xl text-opacity-80 '>{!isFetching && metric ? <span>{metric.users.approved}</span> : <Skeleton h={'20px'} w={'50px'} />}</div>
                                    <div className='flex items-center gap-1 text-[10px]'>
                                        <div>view</div>
                                        <FaLongArrowAltRight className='w-2 h-2' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='sm:px-5 px-3 py-2 sm:py-5 bg-slate-50 dark:bg-neutral-800 rounded-lg shadow-md'>
                        <div className='text-[12px] mb-4 font-bold inline-block px-0'>Banned</div>
                        <div className='flex gap-3 items-center'>
                            <div className='bg-red-100 sm:p-3 p-1'>
                                <FcCancel className='w-7 h-7' />
                            </div>
                            <div className='w-full'>
                                <div className='mb-3 text-[10px]'>
                                    Users banned from services
                                </div>
                                <div className='flex justify-between items-center'>
                                    <div className='text-xl text-opacity-80 '>{!isFetching && metric ? <span>{metric.users.banned}</span> : <Skeleton h={'20px'} w={'50px'} />}</div>
                                    <div className='flex items-center gap-1 text-[10px]'>
                                        <div>view</div>
                                        <FaLongArrowAltRight className='w-2 h-2' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default BusinessOverviewClient