'use client'

import FilterForm from '@/components/FilterForm';
import AutoGenTableViewModal from '@/components/modals/AutoGenTableViewModal';
import http from '@/lib/http';
import { Skeleton } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react'
import { FaAngleRight } from 'react-icons/fa6'
import { useLocation } from 'react-router-dom';

function BusinessServicesTransactionsClient() {

    const [transactions, setTransactions] = useState<any[]>([]);
    const [isFetching, setIsFetching] = useState(true);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("");
    const {pathname} = useLocation();

    const [paginationInput, setPaginationInput] = useState<any>();
    const filterInputs = {
        currency: "ngn",
        service_id: "",
        business_partner_id: "",
        business_console_id: "",
        account: "",
        account_id: "",
        request_type: "",
        date_filter_from: "",
        date_filter_to: "",
        order_by: ""
    }
    function setPaginate(resp: any) {
        setPaginationInput({
            counts: resp.counts,
            totalPages: resp.totalPages,
            currentPage: resp.currentPage,
            listPerPage: resp.listPerPage
        });
    }

    async function fetchServices() {
        try {
            const resp = await http("business/services/transactions", { business_id: getBusinessId(), limit:10 });
            console.log("resp", resp);
            setTransactions(resp.data);
            setPaginate(resp);
            setIsFetching(false);
        } catch (error) {
            console.log(error)
            setIsFetching(false);
        }
    }

    useEffect(() => {
        fetchServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filteredTransactions = useMemo(() => {
        if (transactions.length === 0) return [];
        //  if(search === "");
        const sorted_ = sort === "date-asc" ?
          transactions.slice().sort((a: any, b: any) => new Date(a.created_on).getTime() - new Date(b.created_on).getTime()) :
          sort === "date-desc" ?
            transactions.slice().sort((a: any, b: any) => new Date(b.created_on).getTime() - new Date(a.created_on).getTime()) :
            sort === "z-a" ?
              transactions.slice().sort((a: any, b: any) => b.service.localeCompare(a.service)) :
              transactions.slice().sort((a: any, b: any) => a.service.localeCompare(b.service));
    
        const filtered_ = search.length > 0 ?
          sorted_.filter((e: any) => e.service.toLowerCase().includes(search.toLowerCase())) :
          sorted_;
    
        return filtered_;
    
      }, [search, sort, transactions]);

    function getBusinessId() {
        const split_path = pathname.split('/');
        return split_path[3];
    }

    return (
        <>
            <div className='overflow-auto max-w-full md:max-w-full mb-10'>
                <div className='text-xs flex text-slate-500 items-center gap-3'>
                    <div>Businesses</div>
                    <div><FaAngleRight /></div>
                    <div>Services</div>
                    <div><FaAngleRight /></div>
                    <div>Transactions</div>
                </div>
            </div>

            <div className='mb-10 text-xl'>Services Transactions</div>

            <div className='mb-8 flex gap-5 items-center'>
                <input value={search} type="text" className='px-3 py-3 sm:w-[400px] w-[190px] text-sm border-2 border-neutral-600 dark:bg-neutral-700' onChange={(e) => setSearch(e.target.value)} placeholder='Enter Search Keyword' />
                <div className='flex-1'>
                    <div className='text-xs'>Sort by</div>
                    <select className='text-xs p-1 dark:bg-neutral-700 w-[110px]' value={sort} onChange={(e) => setSort(e.target.value)}>
                        <option value="a-z">A-Z</option>
                        <option value="z-a">Z-A</option>
                        <option value="date-asc">Date (Ascending)</option>
                        <option value="date-desc">Date (Descending)</option>
                    </select>
                </div>
            </div>

            <hr className='mb-10 border-slate-300 dark:border-neutral-700' />

            <FilterForm
                url="business/services/transactions"
                payload={{business_id: getBusinessId()}}
                inputs={filterInputs}
                paginationInput={paginationInput}
                isLoading={isFetching}
                setIsLoading={setIsFetching}
                setState={setTransactions}
                setPaginationInput={setPaginationInput}
                inputtedIsSelectTag={{ request_type: ["Credit", "Debit"] }}
            />

            <div className='mb-10 w-full'>

                <div className="relative overflow-x-auto">
                    <table className="w-full text-xs text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-neutral-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Service name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Currency type
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Provider Amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Partner Amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Active
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                !isFetching ? filteredTransactions.map((d, k) => (
                                    <tr key={k} className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            {d.service}
                                        </th>
                                        <td className="px-6 py-4">
                                            {d.currency_type}
                                        </td>
                                        <td className="px-6 py-4">
                                            {d.amount}
                                        </td>
                                        <td className="px-6 py-4">
                                            {d.provider_amount}
                                        </td>
                                        <td className="px-6 py-4">
                                            {d.partner_amount}
                                        </td>
                                        <td className="px-6 py-4">
                                            {d.active}
                                        </td>
                                        <td className="px-6 py-4 flex gap-3">
                                            <AutoGenTableViewModal title='Transaction' data={d}><span className='font-bold text-blue-600'>View</span></AutoGenTableViewModal>
                                        </td>
                                    </tr>
                                )) :
                                    Array.from(Array(5)).map((k, i) => (
                                        <tr key={i} className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700">
                                            <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                <Skeleton h={'20px'} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <Skeleton h={'20px'} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <Skeleton h={'20px'} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <Skeleton h={'20px'} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <Skeleton h={'20px'} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <Skeleton h={'20px'} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <Skeleton h={'20px'} />
                                            </td>
                                        </tr>
                                    ))
                            }
                        </tbody>
                    </table>
                </div>

            </div>
        </>
    )
}

export default BusinessServicesTransactionsClient