import http from '@/lib/http';
import { handleResponseError } from '@/utils/errors';
import { formatDate, removeUnderscore, upperCaseFirst } from '@/utils/helpers';
import { useToast } from '@chakra-ui/react';
import classNames from 'classnames';
import React, { useState } from 'react'
import { ImSpinner8 } from 'react-icons/im';
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";

type Props = {
    url: string,
    payload?: { business_id: string } | {},
    inputs: any,
    paginationInput: any,
    inputtedIsSelectTag?: { [key: string]: string[] },
    isLoading: boolean,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setState: React.Dispatch<React.SetStateAction<any>>,
    setPaginationInput: React.Dispatch<React.SetStateAction<any>>
}

function FilterForm({
    url,
    payload = {},
    inputs,
    paginationInput,
    inputtedIsSelectTag = {},
    isLoading,
    setIsLoading,
    setState,
    setPaginationInput
}: Props) {

    const toast = useToast();

    const defaultIsSelectTag = {
        active: ["Yes", "no"],
        order_by: ["OLD", "NEW"],
        account: ["user", "business"],
        type: ["Credit", "Debit"],
        country_code: ["NG"]
    }

    const isSelectTag = { ...defaultIsSelectTag, ...inputtedIsSelectTag };

    const [filterInput, setFilterInput] = useState<object>(inputs);

    const handleInputChange = (val: string, fieldName: string) => {
        setFilterInput((prevInput) => ({ ...prevInput, [fieldName]: val }));
    };

    async function sendRquest(page?: string, pageMax?: number) {
        if ((page && pageMax && (parseInt(page) === 0 || parseInt(page) === pageMax + 1))) return false;
        setIsLoading(true);
        try {
            const resp = await http(url, { ...payload, ...filterInput, limit: 10, page: page || paginationInput.currentPage || 0 });
            setState(resp.data);
            // console.log("filtered", resp)
            setPaginationInput({
                counts: resp.counts,
                totalPages: resp.totalPages,
                currentPage: resp.currentPage,
                listPerPage: resp.listPerPage
            });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            handleResponseError(error, toast);
        }
    }

    // function sendFilterRquest() {
    //     sendRquest('0');
    //     setPaginationInput((p: object) => ({...p, currentPage: 0}));
    // }

    return (
        <div className='mb-5'>
            <div className='mb-5 text-purple-500 font-bold text-xs'>Filter Result</div>
            <div className='grid grid-cols-2 md:grid-cols-4 gap-3 mb-8'>
                {
                    Object.keys(inputs).map((fieldName, k) => (
                        <div key={k} className=''>
                            <div className='text-xs font-bold text-slate-500 dark:text-neutral-400 mb-[2px]'>{upperCaseFirst(removeUnderscore(fieldName))}</div>
                            {
                                Object.keys(isSelectTag).includes(fieldName) ?
                                    <select
                                        className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none'
                                        value={(filterInput as any)[fieldName]}
                                        onChange={(e) => handleInputChange(e.target.value, fieldName)}
                                        disabled={isLoading}
                                    >
                                        <option value="">Select</option>
                                        {
                                            ((isSelectTag as any)[fieldName] as string[]).map((o, i) => (
                                                <option value={o}>{o}</option>
                                            ))
                                        }
                                    </select> :
                                    fieldName === "date_filter_from" || fieldName === "date_filter_to" ?
                                        <input
                                            type="datetime-local"
                                            className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none'
                                            onChange={(e) => handleInputChange(formatDate(e.target.value), fieldName)}
                                            disabled={isLoading}
                                        /> :
                                        <input
                                            type="text"
                                            className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none'
                                            value={(filterInput as any)[fieldName]}
                                            onChange={(e) => handleInputChange(e.target.value, fieldName)}
                                            disabled={isLoading} />


                            }
                        </div>
                    ))
                }
                <div className='md:pt-0'>
                    <div className='text-slate-500 dark:text-neutral-400 text-opacity-0 dark:text-opacity-0'>-</div>
                    <div className='flex'>
                        <button className=' px-10 py-2 bg-purple-800 text-sm text-neutral-100 disabled:bg-opacity-50 flex justify-center' onClick={() => sendRquest('0')} disabled={isLoading}>
                            {
                                isLoading ? <ImSpinner8 className='animate-spin w-5 h-5' /> : <span>Submit</span>
                            }
                        </button>
                    </div>
                </div>
            </div>

            <hr className='mb-3 border-slate-300 dark:border-neutral-700' />

            {
                paginationInput && Object.keys(paginationInput).length > 0 && paginationInput.totalPages && paginationInput.totalPages > 1 ?
                    <div className='mb-1'>
                        <div className='flex gap-3 items-center'>
                            <div onClick={() => sendRquest(`${paginationInput.currentPage - 1}`, paginationInput.totalPages)} className={classNames('px-2 py-1 rounded-full font-bold text-sm cursor-pointer border-2 border-purple-500')}>
                                <FaAngleLeft />
                            </div>

                            {
                                (paginationInput.totalPages > 5 && paginationInput.currentPage > 2) &&
                                <>
                                    <div onClick={() => sendRquest(`1`, paginationInput.totalPages)} className={classNames('px-2 py-0 rounded-full font-bold text-sm cursor-pointer')}>
                                        1
                                    </div>
                                    <div>..</div>
                                </>
                            }

                            {
                                Array.from(Array(paginationInput.totalPages)).map((_, i) => (
                                    // i !== 0 &&
                                    <>
                                        {
                                            (i > paginationInput.currentPage - 3) && (i < paginationInput.currentPage + (paginationInput.currentPage <= 1 ? 3 : 1)) &&
                                            <div key={i} onClick={() => sendRquest(`${i + 1}`, paginationInput.totalPages)} className={classNames('px-2 py-0 rounded-full font-bold text-sm cursor-pointer', {
                                                "bg-purple-500": paginationInput.currentPage === i + 1 || (paginationInput.currentPage === 0 && i === 0)
                                            })}>
                                                {i + 1}
                                            </div>
                                        }
                                    </>
                                ))
                            }

                            {
                                (paginationInput.totalPages > 5 && paginationInput.currentPage < paginationInput.totalPages - 1) &&
                                <>
                                    <div>..</div>
                                    <div onClick={() => sendRquest(`${paginationInput.totalPages}`, paginationInput.totalPages)} className={classNames('px-2 py-0 rounded-full font-bold text-sm cursor-pointer')}>
                                        {paginationInput.totalPages}
                                    </div>
                                </>
                            }


                            <div onClick={() => sendRquest(`${paginationInput.currentPage + 1}`, paginationInput.totalPages)} className={classNames('px-2 py-1 rounded-full font-bold text-sm cursor-pointer border-2 border-purple-500')}>
                                <FaAngleRight />
                            </div>
                        </div>
                    </div> : <></>
            }

        </div>
    )
}

export default FilterForm