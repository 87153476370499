'use client'

import http, { httpRaw } from '@/lib/http';
import { IBusinessCreate } from '@/types/user.type';
import { handleResponseError } from '@/utils/errors';
import { useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { ImSpinner8 } from 'react-icons/im';
import { useNavigate } from 'react-router-dom';

function BusinessCreateClient() {

    const toast = useToast();
    const navigate = useNavigate();
    const [isSending, setIsSending] = useState(false);
    // const [isFetchingStates, setIsFetchingStates] = useState(true);
    // const [isFetchingLgas, setIsFetchingLgas] = useState(false);
    const [states, setStates] = useState<{
        "state_id": string,
        "state_name": string
    }[]>([]);
    const [lgas, setLgas] = useState<{
        "lga_id": string,
        "state_id": string,
        "country_code": string,
        "lga_name": string
    }[]>([]);
    const [businessCategories, setBusinessCategories] = useState<{
        "business_category_id": string,
        "business_category": string
    }[]>([]);
    const [createBusinessInput, setCreateBusinessInput] = useState<IBusinessCreate>({
        name: "",
        business_category_id: "",
        email: "",
        phone: "",
        short_name: "",
        primary_contact: {
            email: "",
            phone: "",
            lastname: "",
            firstname: ""
        },
        address: "",
        state_id: "",
        lga_id: "",
        city: ""
    });


    async function createAccount() {
        setIsSending(true)
        try {
            const resp = await http("business/account/create", { ...createBusinessInput });
            console.log("update resp", resp.data)
            toast({
                title: 'Success',
                description: resp.message,
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
            navigate('/d/businesses')
        } catch (error) {
            console.log(error)
            setIsSending(false)
            handleResponseError(error, toast);
        }
    }

    async function getStates() {
        try {
            const resp = await httpRaw("https://api.Lucrestack.co/misc/state", { country_code: "NG" }, "pk_73122563bee4bc7cb24075bef6d02098");
            console.log("states", resp.data)
            setStates(resp.data)
            // setIsFetchingStates(false);
        } catch (error) {
            console.log(error)
            // setIsFetchingStates(false);
            handleResponseError(error, toast);
        }
    }

    async function getLgas() {
        // setIsFetchingLgas(true);
        setLgas([]);
        try {
            const resp = await httpRaw("https://api.Lucrestack.co/misc/lga", { state_id: createBusinessInput.state_id }, "pk_73122563bee4bc7cb24075bef6d02098");
            console.log("lgas", resp.data)
            setLgas(resp.data)
            // setIsFetchingLgas(false);
        } catch (error) {
            console.log(error)
            // setIsFetchingLgas(false);
            handleResponseError(error, toast);
        }
    }

    async function getBusinessCategories() {
        try {
            const resp = await httpRaw("https://api.Lucrestack.co/misc/business-category", {}, "pk_73122563bee4bc7cb24075bef6d02098");
            console.log("states", resp.data)
            setBusinessCategories(resp.data)
            // setIsFetchingStates(false);
        } catch (error) {
            console.log(error)
            // setIsFetchingStates(false);
            handleResponseError(error, toast);
        }
    }

    useEffect(() => {
        getStates();
        getBusinessCategories()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        console.log("state_id", createBusinessInput.state_id)
        if (createBusinessInput.state_id.length > 0) {
            getLgas();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createBusinessInput.state_id])

    return (
        <>

            <div className='sm:px-10 px-5 py-8 w-full'>
                <div className='mb-8'>
                    <div className='text-xl font-bold mb-1'>Create Business</div>
                    <div className='text-xs text-neutral-500'>Create a business account</div>
                </div>

                <div className='mb-10 w-full'>
                    <hr className='mb-6 border-slate-300 dark:border-neutral-700' />

                    <div className='mb-8'>
                        <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>Business information</div>
                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mb-5'>
                            <div className=''>
                                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Email</div>
                                <input type="text" value={createBusinessInput.email} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' onChange={(e) => setCreateBusinessInput((b) => ({ ...b, email: e.target.value }))} placeholder='' disabled={isSending} />
                            </div>
                            <div className=''>
                                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Name</div>
                                <input type="text" value={createBusinessInput.name} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' onChange={(e) => setCreateBusinessInput((b) => ({ ...b, name: e.target.value }))} placeholder='' disabled={isSending} />
                            </div>
                            <div className=''>
                                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Short name</div>
                                <input type="text" value={createBusinessInput.short_name} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' onChange={(e) => setCreateBusinessInput((b) => ({ ...b, short_name: e.target.value }))} placeholder='' disabled={isSending} />
                            </div>
                            <div className=''>
                                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Phone</div>
                                <input type="text" value={createBusinessInput.phone} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' onChange={(e) => setCreateBusinessInput((b) => ({ ...b, phone: e.target.value }))} placeholder='' disabled={isSending} />
                            </div>
                            <div className=''>
                                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Category</div>
                                <select value={createBusinessInput.business_category_id} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' onChange={(e) => setCreateBusinessInput((b) => ({ ...b, business_category_id: e.target.value }))} disabled={isSending}>
                                    <option value="">Select</option>
                                    {businessCategories.map((e, i) => (
                                        <option key={i} value={e.business_category_id}>{e.business_category}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>Business Location</div>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mb-10'>
                        <div className=''>
                            <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Address</div>
                            <input type="text" value={createBusinessInput.address} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' onChange={(e) => setCreateBusinessInput((b) => ({ ...b, address: e.target.value }))} placeholder='' disabled={isSending} />
                        </div>
                        <div className=''>
                            <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>State</div>
                            <select value={createBusinessInput.state_id} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' onChange={(e) => setCreateBusinessInput((b) => ({ ...b, state_id: e.target.value }))} disabled={isSending}>
                                <option value="">Select</option>
                                {states.map((e, i) => (
                                    <option key={i} value={e.state_id}>{e.state_name}</option>
                                ))}
                            </select>
                        </div>
                        <div className=''>
                            <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>City</div>
                            <input type="text" value={createBusinessInput.city} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' onChange={(e) => setCreateBusinessInput((b) => ({ ...b, city: e.target.value }))} placeholder='' disabled={isSending} />
                        </div>
                        <div className=''>
                            <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>LGA</div>
                            <select value={createBusinessInput.lga_id} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' onChange={(e) => setCreateBusinessInput((b) => ({ ...b, lga_id: e.target.value }))} disabled={isSending}>
                                <option value="">Select</option>
                                {lgas.map((e, i) => (
                                    <option key={i} value={e.lga_id}>{e.lga_name}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className='mb-10'>
                        <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>Primary contact</div>
                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5'>
                            <div className=''>
                                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Firstname</div>
                                <input value={createBusinessInput.primary_contact?.firstname} type="text" className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' onChange={(e) => setCreateBusinessInput((b) => ({ ...b, primary_contact: { ...b.primary_contact!, firstname: e.target.value } }))} placeholder='' disabled={isSending} />
                            </div>
                            <div className=''>
                                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Lastname</div>
                                <input value={createBusinessInput.primary_contact?.lastname} type="text" className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' onChange={(e) => setCreateBusinessInput((b) => ({ ...b, primary_contact: { ...b.primary_contact!, lastname: e.target.value } }))} placeholder='' disabled={isSending} />
                            </div>
                            <div className=''>
                                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Email</div>
                                <input value={createBusinessInput.primary_contact?.email} type="text" className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' onChange={(e) => setCreateBusinessInput((b) => ({ ...b, primary_contact: { ...b.primary_contact!, email: e.target.value } }))} placeholder='' disabled={isSending} />
                            </div>
                            <div className=''>
                                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Phone</div>
                                <input value={createBusinessInput.primary_contact?.phone} type="text" className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' onChange={(e) => setCreateBusinessInput((b) => ({ ...b, primary_contact: { ...b.primary_contact!, phone: e.target.value } }))} placeholder='' disabled={isSending} />
                            </div>
                        </div>
                    </div>

                    <div>
                        <button className='px-10 py-2 bg-purple-800 text-sm text-neutral-100 disabled:bg-opacity-50 flex justify-center' onClick={() => createAccount()} disabled={isSending}>
                            {
                                isSending ? <ImSpinner8 className='animate-spin w-5 h-5' /> : <span>Submit</span>
                            }
                        </button>
                    </div>

                </div>
            </div>
        </>
    )
}

export default BusinessCreateClient