'use client'

import React, { useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { useTheme } from '@/contexts/themeProvider';
import classNames from 'classnames';
import { spreadArrToString, upperCaseFirst } from '@/utils/helpers';

interface DataItem {
  [key: string]: any;
}

type Props = {
  data: DataItem;
  isOpen: boolean;
  exclude?: string[]
};

const DataGrid: React.FC<Props> = ({ data, isOpen, exclude = [] }) => {
  const [nestedDatas, setNestedDatas] = React.useState<DataItem>({});

  useEffect(() => {
    const data_ = JSON.parse(JSON.stringify(data));
    const newNestedData: DataItem = {};
    Object.entries(data_).forEach(([key, value]) => {
      if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
        // console.log("scrapped", key)
        newNestedData[key] = value;
        delete data_[key];
      }
    });
    setNestedDatas(newNestedData);
    // console.log("newNestedData", newNestedData)
  }, [data, isOpen]);

  // useEffect(() => {
  //   console.log("nestedDatas", nestedDatas);
  // }, [nestedDatas])

  // spreadArrToString
  const renderGridItems = (obj: DataItem) =>
    Object.entries(obj).map(([key, value], k) => (
      ((typeof value === 'object' && Array.isArray(value)) || (typeof value !== 'object')) && (exclude.length > 0 && !exclude.includes(key)) && <div key={k} className="grid-item">
        <div className="text-sm font-bold text-slate-500 dark:text-neutral-400">{upperCaseFirst(key.replace(/_/g, ' '))}</div>
        <div className="text-sm">{value.length > 0 ? Array.isArray(value) ? spreadArrToString(value) : value : "_"}</div>
      </div>
    ));

  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-10">
        {renderGridItems(data)}        
      </div>

      <div>
        {Object.keys(nestedDatas).length > 0 && Object.keys(nestedDatas).map((_, i) => (
          <div className="mb-10" key={i}>
            <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-3 text-sm font-bold">
              {Object.keys(nestedDatas)[i]}
            </div>
            <DataGrid data={nestedDatas[Object.keys(nestedDatas)[i]]} isOpen={isOpen} exclude={exclude} />
          </div>
        ))}
      </div>
    </>
  );
};

function AutoGenNormalViewModal({
  children,
  data,
  title,
  exclude = [""]
}: Readonly<{
  title: string,
  children: React.ReactNode,
  data: any,
  exclude?: string[]
}>) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { resolvedTheme } = useTheme();

  return (
    <>
      <div onClick={onOpen} className='inline-block cursor-pointer'>{children}</div>

      <Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} size={"4xl"} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>{title}</ModalHeader>
          <ModalCloseButton className={classNames('outline-none', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })} />
          <ModalBody className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>
            <DataGrid data={data} isOpen={isOpen} exclude={exclude} />
          </ModalBody>
          <ModalFooter className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>
            <button onClick={onClose}>Close</button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AutoGenNormalViewModal