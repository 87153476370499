"use client"

import React, { useState, useEffect, useMemo } from 'react'
import UpdateServiceModal from '../modals/UpdateServiceModal'
import classNames from 'classnames';
import http from '@/lib/http';
import { Skeleton } from '@chakra-ui/react';
import AutoGenNormalViewModal from '../modals/AutoGenNormalViewModal';
import AutoGenTableViewModal from '../modals/AutoGenTableViewModal';
import FilterForm from '../FilterForm';

function ServicesClient() {

  const [tab, setTab] = useState<"details" | "transactions">("details");
  const [details, setDetails] = useState<any[]>([]);
  const [transactions, setTransactions] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");

  const [paginationInputDetails, setPaginationInputDetails] = useState<any>();
  const filterInputsDetails = {
    currency: "ngn",
    slug: "",
    service_id: "",
    collection: "",
    active: "",
    order_by: ""
  }
  function setPaginateDetails(resp: any) {
    setPaginationInputDetails({
      counts: resp.counts,
      totalPages: resp.totalPages,
      currentPage: resp.currentPage,
      listPerPage: resp.listPerPage
    });
  }

  const [paginationInputTransactions, setPaginationInputTransactions] = useState<any>();
  const filterInputsTransactions = {
    currency: "ngn",
    service_id: "",
    business_partner_id: "",
    business_console_id: "",
    account: "",
    account_id: "",
    request_type: "",
    date_filter_from: "",
    date_filter_to: "",
    order_by: ""
  }
  function setPaginateTransactions(resp: any) {
    setPaginationInputTransactions({
      counts: resp.counts,
      totalPages: resp.totalPages,
      currentPage: resp.currentPage,
      listPerPage: resp.listPerPage
    });
  }

  async function fetchServices() {
    try {
      const resp = await Promise.all([
        http("services/details", { limit: 10 }),
        http("services/transactions", { limit: 10 })
      ]);
      console.log("resp", resp);
      setDetails(resp[0].data);
      setTransactions(resp[1].data);
      setPaginateDetails(resp[0]);
      setPaginateTransactions(resp[1]);
      setIsFetching(false);
    } catch (error) {
      console.log(error)
      setIsFetching(false);
    }
  }

  function updateService(service_id: string, newActive: string) {
    const index = details.findIndex(service => service.service_id === service_id);
    if (index !== -1) {
      const updatedServices = [...details];
      updatedServices[index].active = newActive;
      setDetails(updatedServices);
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);

  const filteredTransactions = useMemo(() => {
    if (transactions.length === 0) return [];
    //  if(search === "");
    const sorted_ = sort === "date-asc" ?
      transactions.slice().sort((a: any, b: any) => new Date(a.created_on).getTime() - new Date(b.created_on).getTime()) :
      sort === "date-desc" ?
        transactions.slice().sort((a: any, b: any) => new Date(b.created_on).getTime() - new Date(a.created_on).getTime()) :
        sort === "z-a" ?
          transactions.slice().sort((a: any, b: any) => b.service.localeCompare(a.service)) :
          transactions.slice().sort((a: any, b: any) => a.service.localeCompare(b.service));

    const filtered_ = search.length > 0 ?
      sorted_.filter((e: any) => e.service.toLowerCase().includes(search.toLowerCase())) :
      sorted_;

    return filtered_;

  }, [search, sort, transactions]);

  const filteredDetails = useMemo(() => {
    if (details.length === 0) return [];
    //  if(search === "");
    const sorted_ = sort === "date-asc" ?
      details.slice().sort((a: any, b: any) => new Date(a.created_on).getTime() - new Date(b.created_on).getTime()) :
      sort === "date-desc" ?
        details.slice().sort((a: any, b: any) => new Date(b.created_on).getTime() - new Date(a.created_on).getTime()) :
        sort === "z-a" ?
          details.slice().sort((a: any, b: any) => b.slug.toLowerCase() < a.slug.toLowerCase() ? -1 : 1) :
          details.slice().sort((a: any, b: any) => a.slug.toLowerCase() < b.slug.toLowerCase() ? -1 : 1);

    const filtered_ = search.length > 0 ?
      sorted_.filter((e: any) => e.slug.toLowerCase().includes(search.toLowerCase())) :
      sorted_;

    return filtered_;

  }, [search, sort, details]);

  return (
    <>
      <div className='sm:px-10 px-5 py-8 w-full'>
        <div className='mb-10 text-xl'>Lucrestack Services</div>

        <div className='mb-8 flex gap-5 items-center'>
          <input value={search} type="text" className='px-3 py-3 sm:w-[400px] w-[190px] text-sm border-2 border-neutral-600 dark:bg-neutral-700' onChange={(e) => setSearch(e.target.value)} placeholder='Enter Search Keyword' />
          <div className='flex-1'>
            <div className='text-xs'>Sort by</div>
            <select className='text-xs p-1 dark:bg-neutral-700 w-[110px]' value={sort} onChange={(e) => setSort(e.target.value)}>
              <option value="a-z">A-Z</option>
              <option value="z-a">Z-A</option>
              <option value="date-asc">Date (Ascending)</option>
              <option value="date-desc">Date (Descending)</option>
            </select>
          </div>
        </div>

        <hr className='mb-10 border-slate-300 dark:border-neutral-700' />

        <div className='mb-8 flex gap-5'>
          <button className={classNames('text-[13px]', {
            'text-purple-600 underline font-bold': tab === "details",
            '': tab === "transactions"
          })} onClick={() => { setTab("details") }}>Details</button>
          <button className={classNames('text-[13px]', {
            'text-purple-600 underline font-bold': tab === "transactions",
            '': tab === "transactions"
          })} onClick={() => { setTab("transactions") }}>Transactions</button>
        </div>

        <div className='mb-10 w-full'>

          {
            tab === "details" ?

              <>
                <FilterForm
                  url="services/details"
                  inputs={filterInputsDetails}
                  paginationInput={paginationInputDetails}
                  isLoading={isFetching}
                  setIsLoading={setIsFetching}
                  setState={setDetails}
                  setPaginationInput={setPaginationInputDetails}
                />

                <div className="relative overflow-auto max-h-[500px]">
                  <table className="w-full text-xs text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-neutral-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Slug
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Currency Type
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Amount
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Provider Amount
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Active
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        !isFetching ? filteredDetails.map((d, k) => (
                          <tr key={k} className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              {d.slug}
                            </th>
                            <td className="px-6 py-4">
                              {d.currency_type}
                            </td>
                            <td className="px-6 py-4">
                              {d.amount}
                            </td>
                            <td className="px-6 py-4">
                              {d.provider_amount}
                            </td>
                            <td className="px-6 py-4">
                              {d.active}
                            </td>
                            <td className="px-6 py-4 flex gap-3">
                              <AutoGenNormalViewModal title='Service' data={d}><span className='font-bold text-blue-600'>View</span></AutoGenNormalViewModal>
                              <UpdateServiceModal data={d} updateService={updateService}><span className='font-bold text-blue-600'>Update</span></UpdateServiceModal>
                            </td>
                          </tr>
                        )) :
                          Array.from(Array(5)).map((k, i) => (
                            <tr key={i} className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700">
                              <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                <Skeleton h={'20px'} />
                              </td>
                              <td className="px-6 py-4">
                                <Skeleton h={'20px'} />
                              </td>
                              <td className="px-6 py-4">
                                <Skeleton h={'20px'} />
                              </td>
                              <td className="px-6 py-4">
                                <Skeleton h={'20px'} />
                              </td>
                              <td className="px-6 py-4">
                                <Skeleton h={'20px'} />
                              </td>
                              <td className="px-6 py-4">
                                <Skeleton h={'20px'} />
                              </td>
                            </tr>
                          ))
                      }
                    </tbody>
                  </table>
                </div>
              </> :
              // Transactions
              <>
                <FilterForm
                  url="services/transactions"
                  inputs={filterInputsTransactions}
                  paginationInput={paginationInputTransactions}
                  isLoading={isFetching}
                  setIsLoading={setIsFetching}
                  setState={setTransactions}
                  setPaginationInput={setPaginationInputTransactions}
                  inputtedIsSelectTag={{ request_type: ["Credit", "Debit"] }}
                />
                <div className="relative overflow-x-auto">
                  <table className="w-full text-xs text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-neutral-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Service name
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Currency Type
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Amount
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Business Amount
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Partner Amount
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        !isFetching ? filteredTransactions.map((d, k) => (
                          <tr key={k} className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              {d.service}
                            </th>
                            <td className="px-6 py-4">
                              {d.currency_type}
                            </td>
                            <td className="px-6 py-4">
                              {d.amount}
                            </td>
                            <td className="px-6 py-4">
                              {d.business_amount}
                            </td>
                            <td className="px-6 py-4">
                              {d.partner_amount}
                            </td>
                            <td className="px-6 py-4 flex gap-3">
                              <AutoGenTableViewModal title='Transaction' data={d}><span className='font-bold text-blue-600'>View</span></AutoGenTableViewModal>
                            </td>
                          </tr>
                        )) :
                          Array.from(Array(5)).map((k, i) => (
                            <tr key={i} className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700">
                              <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                <Skeleton h={'20px'} />
                              </th>
                              <td className="px-6 py-4">
                                <Skeleton h={'20px'} />
                              </td>
                              <td className="px-6 py-4">
                                <Skeleton h={'20px'} />
                              </td>
                              <td className="px-6 py-4">
                                <Skeleton h={'20px'} />
                              </td>
                              <td className="px-6 py-4">
                                <Skeleton h={'20px'} />
                              </td>
                              <td className="px-6 py-4">
                                <Skeleton h={'20px'} />
                              </td>
                            </tr>
                          ))
                      }
                    </tbody>
                  </table>
                </div>
              </>
          }

        </div>
      </div>
    </>
  )
}

export default ServicesClient